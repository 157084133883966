/** Actions' constants */
export const ACTION_SET_WISHLIST_ITEMS = 'ACTION_SET_WISHLIST_ITEMS';
export const ACTION_SET_WISHLIST_PRODUCTS = 'ACTION_SET_WISHLIST_PRODUCTS';

/** Initial state */
export const INITIAL_STATE = {
  wishlistItems: [], //includes array from api, which includes product id, added date, price
  wishlistPlain: [], //Array<string> version of wishlistItems
  wishlistProducts: [], //includes info from search api product endpoint for each of valid products
};

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_WISHLIST_ITEMS:
      return { ...currentState, wishlistItems: payload, wishlistPlain: payload.map(({ productId }) => productId) };
    case ACTION_SET_WISHLIST_PRODUCTS:
      return { ...currentState, wishlistProducts: payload };
    default:
      return currentState;
  }
};

/** Action (creators) */
export const setWishlistItems = (detailedWishlist) => ({ payload: detailedWishlist, type: ACTION_SET_WISHLIST_ITEMS });
export const setWishlistProducts = (products) => ({ payload: products, type: ACTION_SET_WISHLIST_PRODUCTS });
