import { isServer } from '../../utils/isServer';
import { parseValidJSON } from '../../utils/parseValidJSON';

/** Actions' constants */
export const ACTION_SET_ANONYMOUS_RECENTLY_VIEWED_PRODUCTS = 'SET_ANONYMOUS_RECENTLY_VIEWED_PRODUCTS';

/** Initial state */
//todo: check if this should be done in useEffect
export const INITIAL_STATE = isServer ? [] : parseValidJSON(localStorage.getItem('rVP')) || [];

export const reducer = (currentState = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_SET_ANONYMOUS_RECENTLY_VIEWED_PRODUCTS:
      return payload;
    default:
      return currentState;
  }
};

/** Action (creators) */
export const setAnonymousRecentlyViewedProducts = (payload) => ({
  payload,
  type: ACTION_SET_ANONYMOUS_RECENTLY_VIEWED_PRODUCTS,
});
